
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { TableOptions } from "@/models/TableOptions";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import html2pdf from 'html2pdf.js';

interface ComprovanteTransferencia {
  valorFormatado: string;
  data: string;
  origem: { nome: string; documento: string };
  destino: { nome: string; documento: string };
  identificacao: string;
  transacao: string;
}

interface ComprovanteSaque {
  valorFormatado: string;
  solicitacao: string;
  nome: string;
  documento: string;
  tipo: string;
  dados: {
    chave?: string;
    banco: string;
    agencia: string;
    conta: string;
    titular?: string;
    documento?: string;
    endToEndId?: string;
  };
  processamento?: string;
  status: string;
  motivo_recusa?: string;
}

interface ComprovanteBoleto {
  BancoCedente: string;
  LinhaDigitavel: string;
  status: string;
  beneficiario: { nome: string; documento: string };
  sacado: { nome: string; documento: string };
  pagador: { nome: string; documento: string };
  DataVencimentoRegistro: string;
  DataLiquidacao: string;
  ValorNominalFormatado: string;
  ValorPagamentoAtualizadoFormatado: string;
  Autenticacao: string;
  ProtocoloId: string;
  DataOperacao: string;
}

interface ComprovanteCartao {
  tipo: string;
  status: string;
  data: string;
  estabelecimento: string;
  valorFormatado: string;
  transacao: string;
  icone: string;
}

interface ComprovanteRecarga {
  recarga_comprovante: string;
  recarga_autenticacao: string;
  recarga_protocolo: string;
  recarga_autenticacao_api: string;
  recarga_data: string;
}

interface ComprovantePixEnviado {
  valorFormatado: string;
  dataEfetivada: string;
  destino: {
    name: string;
    taxId: string;
    bank: string;
    branch: string;
    accountType: string;
  };
  origem: {
    name: string;
    taxId: string;
  };
  endToEndId: string;
  status?: string;
}

interface ComprovantePixRecebido {
  valorFormatado: string;
  dataEfetivada: string;
  origem: {
    name: string;
    taxId: string;
    bank: string;
    branch: string;
    accountType: string;
  };
  destino: {
    name: string;
    taxId: string;
  };
  endToEndId: string;
}

export interface IbMovimentos {
  movimento_cod?: string;
  movimento_transacao?: string;
  movimento_pessoa?: string;
  movimento_tipo?: string;
  movimento_data?: string;
  movimento_origem?: number;
  movimento_origem_pessoa?: string;
  movimento_destino_pessoa?: string;
  movimento_valor?: string;
  movimento_historico?: string;
  movimento_ref?: string;
  movimento_icone?: string;
  movimento_id_transferencia_api?: string;
  movimento_computasaldo?: number;
  movimento_canal?: string;
}

export default Vue.extend({
  data() {
    return {
      showSaldo: false,
      loadingBalance: false,
      hasLoadedData: false,
      dialogComprovanteTransferencia: false,
      dialogComprovanteSaque: false,
      dialogComprovantePixEnviado: false,
      dialogComprovantePixRecebido: false,
      dialogComprovanteBoleto: false,
      dialogComprovanteCartao: false,
      dialogTransferSaldo: false,
      dialogFiltrosMobile: false,
      dialogComprovanteRecarga: false,

      dadosComprovanteTransferencia: {} as ComprovanteTransferencia,
      dadosComprovanteSaque: {} as ComprovanteSaque,
      dadosComprovanteBoleto: {} as ComprovanteBoleto,
      dadosComprovanteCartao: {} as ComprovanteCartao,
      dadosComprovanteRecarga: {} as ComprovanteRecarga,
      dadosComprovantePixEnviado: {} as ComprovantePixEnviado,
      dadosComprovantePixRecebido: {} as ComprovantePixRecebido,

      panel: [] as number[],
      startPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      endPeriod: moment().format("YYYY-MM-DDT23:59"),
      defaultStartPeriod: moment().add(-1, "hours").format("YYYY-MM-DDTHH:mm"),
      defaultEndPeriod: moment().format("YYYY-MM-DDT23:59"),
      // startDate: moment().add(-15, "days").format("YYYY-MM-DD"),
      startDate: moment().format("YYYY-MM-DD"),
      //defaultStartDate: moment().add(-15, "days").format("YYYY-MM-DD"),
      defaultStartDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      defaultEndDate: moment().format("YYYY-MM-DD"),
      maxDate: moment().format("YYYY-MM-DD"),
      lang: navigator.language,
      btnReloadLoading: false,
      btnDownloadDisabled: true,
      btnDownloadLoading: false,
      moment: moment,
      filtrosMobile: "",
      search: "",
      page: 1,
      errors: [],
      pageCount: 0,
      itemsPerPage: 10,
      totalEntradas: 0,
      totalSaidas: 0,
      saldoCliente: 0,
      somaDaPagina: 0,
      somaTotal: 0,
      menuInitialDate: false,
      pages: 7,
      menuFinalDate: false,
      menuInitialDateMobile: false,
      menuFinalDateMobile: false,
      //dadosComprovanteTransferencia: [],
      //dadosComprovanteSaque: [],
      //dadosComprovanteBoleto: [],
      //dadosComprovanteCartao: [],
      //dadosComprovanteRecarga: [],
      //dadosComprovantePixEnviado: [],
      //dadosComprovantePixRecebido: [],
      options: {} as TableOptions,
      toExport: false,
      selectedEntryTipoMovimentos: { id: "T", descricao: "Tudo" },
      selectedEntryFiltrosMovimentos: { id: "T", descricao: "Tudo" },
      desserts: {},
      loadingDesserts: false,
      totalDesserts: 0,
      timeout: 0,
      permissoes: { perm_extrato: false, perm_saldo: false },
    };
  },
  created() {
    this.permissoes = Auth.getUserPermissoes();

    if (!this.permissoes.perm_extrato) {
      this.$router.go(-1);
    }

    if (this.permissoes.perm_saldo) {
      this.getBalance();
    }
    //this.loadDatas();
    
  },
  methods: {
    
    async downloadComprovantePDF(origem: number | string, id: string) {
      try {
        Overlay.show();
        await this.Comprovante(Number(origem), id);
        
        // Espera adicional para garantir que os dados foram carregados
        await new Promise(resolve => setTimeout(resolve, 1000));

        const dialogMap = {
          5: 'dadosComprovanteTransferencia',
          6: 'dadosComprovanteTransferencia',
          10: 'dadosComprovanteSaque',
          21: 'dadosComprovanteBoleto',
          25: 'dadosComprovanteCartao',
          18: 'dadosComprovanteRecarga',
          34: 'dadosComprovantePixRecebido',
          35: 'dadosComprovantePixRecebido',
          36: 'dadosComprovantePixEnviado'
        };

        const dataKey = dialogMap[origem as keyof typeof dialogMap];

        if (!dataKey) {
          //throw new Error(`Tipo de comprovante não reconhecido: ${origem}`);
          Snackbar.show(this.$t('components.Movimento.template.review.unrecognizedReceiptType', { origem }).toString(), "error");
        }

        const data = (this as any)[dataKey];

        if (!data) {
          //throw new Error(`Dados não encontrados para o comprovante: ${dataKey}`);
          Snackbar.show(this.$t('components.Movimento.template.review.dataNotFoundForReceipt', { dataKey }).toString(), "error");
        }

        const element = document.createElement('div');
        element.innerHTML = this.renderComprovante(dataKey, data);
        document.body.appendChild(element);

        const opt = {
          margin: 10,
          filename: `comprovante_${origem}_${id}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { 
            scale: 2,
            logging: true,
            useCORS: true
          },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        await html2pdf().from(element).set(opt).save();

        document.body.removeChild(element);

        
      } catch (error) {
        Snackbar.show("Erro ao gerar o PDF do comprovante. Por favor, tente novamente.", "error");
        Snackbar.show(this.$t('components.Movimento.template.review.pdfGenerationError').toString(), "error");
      } finally {
        Overlay.hide();
      }
    },
    renderComprovante(dataKey: string, data: any): string {
      
      let content = '';
      
      switch(dataKey) {
        case 'dadosComprovantePixRecebido':
        case 'dadosComprovantePixEnviado':
          content = this.renderComprovantePix(data);
          break;
        case 'dadosComprovanteTransferencia':
          content = this.renderComprovanteTransferencia(data);
          break;
        case 'dadosComprovanteRecarga':
          content = this.renderComprovanteRecarga(data);
          break;
        case 'dadosComprovanteCartao':
          content = this.renderComprovanteCartao(data);
        break;
        case 'dadosComprovanteBoleto':
          content = this.renderComprovanteBoleto(data);
          break;
        case 'dadosComprovanteSaque':
          content = this.renderComprovanteSaque(data);
          break;

        default:
          content = this.renderComprovanteGenerico(dataKey, data);
      }

      return `
        <div style="font-family: Arial, sans-serif; background-color: #fff9c4; padding: 20px; max-width: 400px; margin: 0 auto;">
          <div style="text-align: center;">
            <img src="${require('@/assets/logo-text-black.png')}" style="width: 120px; margin-bottom: 20px;" />
            <h3 style="font-weight: bold; margin-top: 20px; margin-bottom: 15px;">
              ${this.$t('components.Movimento.template.review.receipt').toString()}
            </h3>
          </div>
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          ${content}
        </div>
      `;
    },
    renderComprovanteGenerico(dataKey: string, data: any): string {
      return `
        <div style="text-align: center;">
          <h2>Comprovante ${dataKey}</h2>
          <p>Valor: R$ ${data.valorFormatado || ''}</p>
          <p>Data: ${data.data ? moment(data.data).locale(this.lang).format("DD/MM/yyyy HH:mm:ss") : ''}</p>
          
        </div>
      `;
    },

    renderComprovanteSaque(data: ComprovanteSaque): string {
      if (!data) {
        //return '<p>Erro: Dados incompletos para gerar o comprovante</p>';
        return `<p>${this.$t('components.Movimento.template.review.incompleteDataForReceipt').toString()}</p>`;
      }

      const renderStatusChip = (status: string) => {
        let color, text;
        switch (status) {
          case 'APROVADO':
            color = 'green';
            text = this.$t('components.Movimento.template.dialog.withdrawalReceipt.approved').toString();
            break;
          case 'PENDENTE':
            color = 'amber';
            text = this.$t('components.Movimento.template.dialog.common.awaitingApproval').toString();
            break;
          case 'RECUSADO':
            color = 'red';
            text = data.motivo_recusa || this.$t('components.Movimento.template.dialog.common.refused').toString();
            break;
          default:
            return '';
        }
        return `
          <div style="background-color: ${color}; color: white; padding: 5px; display: inline-block; border-radius: 4px; font-size: 12px; margin-top: 10px;">
            ${text}
          </div>
        `;
      };

      const renderInfoTable = (info: Array<{key: string, value: string}>) => `
        <table style="width: 100%; border-collapse: collapse;">
          ${info.map(item => `
            <tr>
              <td style="padding: 5px 0;">${item.key}</td>
              <td style="text-align: right;">${item.value}</td>
            </tr>
          `).join('')}
        </table>
      `;

      const clientDataInfo = [
        {key: this.$t('components.Movimento.template.dialog.common.name').toString(), value: data.nome},
        {key: this.$t('components.Movimento.template.dialog.common.document').toString(), value: data.documento}
      ];

      const renderCommonInfo = () => `
        <div style="text-align: center;">
          <h3 class="fw-bolder mt-5 mb-3">${this.$t('components.Movimento.template.dialog.withdrawalReceipt.title')}</h3>
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          <h2 class="mt-3 mb-3">R$ ${data.valorFormatado}</h2>
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          ${renderStatusChip(data.status)}
        </div>
        
        <h3 class="mt-3">${this.$t('components.Movimento.template.dialog.withdrawalReceipt.clientData')}</h3>
        ${renderInfoTable(clientDataInfo)}
        
        <h3 class="mt-5">${this.$t('components.Movimento.template.dialog.withdrawalReceipt.transferData')}</h3>
      `;

      const renderPixInfo = () => {
        const pixInfo = [
          {key: this.$t('components.Movimento.template.dialog.withdrawalReceipt.type').toString(), value: data.tipo},
          {key: this.$t('components.Movimento.template.dialog.withdrawalReceipt.request').toString(), value: moment(data.solicitacao).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")},
          {key: this.$t('components.Movimento.template.dialog.withdrawalReceipt.key').toString(), value: data.dados.chave || ''},
          {key: this.$t('components.Movimento.template.dialog.common.institution').toString(), value: data.dados.banco},
          {key: this.$t('components.Movimento.template.dialog.withdrawalReceipt.agencyAccount').toString(), value: `${data.dados.agencia} / ${data.dados.conta}`},
          {key: this.$t('components.Movimento.template.dialog.withdrawalReceipt.holder').toString(), value: data.dados.titular || ''},
          {key: this.$t('components.Movimento.template.dialog.common.document').toString(), value: data.dados.documento || ''},
          {key: this.$t('components.Movimento.template.dialog.common.transaction').toString(), value: data.dados.endToEndId || ''}
        ];
        
        return `
          ${renderInfoTable(pixInfo)}
          ${data.processamento ? `
            <p>${this.$t('components.Movimento.template.dialog.withdrawalReceipt.processing').toString()}: 
               ${moment(data.processamento).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")}</p>
          ` : ''}
        `;
      };

      const renderOtherInfo = () => {
        const otherInfo = [
          {key: this.$t('components.Movimento.template.dialog.withdrawalReceipt.type').toString(), value: data.tipo},
          {key: this.$t('components.Movimento.template.dialog.withdrawalReceipt.request').toString(), value: moment(data.solicitacao).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")},
          {key: this.$t('components.Movimento.template.dialog.common.institution').toString(), value: data.dados.banco},
          {key: this.$t('components.Movimento.template.dialog.common.agency').toString(), value: data.dados.agencia},
          {key: this.$t('components.Movimento.template.dialog.withdrawalReceipt.account').toString(), value: data.dados.conta}
        ];
        
        return `
          ${renderInfoTable(otherInfo)}
          ${data.processamento ? `
            <p>${this.$t('components.Movimento.template.dialog.withdrawalReceipt.processing').toString()}: 
               ${moment(data.processamento).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")}</p>
          ` : ''}
        `;
      };

      return `
        ${renderCommonInfo()}
        ${data.tipo === 'PIX' ? renderPixInfo() : renderOtherInfo()}
      `;
    },
    renderComprovanteBoleto(data: ComprovanteBoleto): string {
      
      if (!data) {
        //return '<p>Erro: Dados incompletos para gerar o comprovante</p>';
        return `<p>${this.$t('components.Movimento.template.review.incompleteDataForReceipt').toString()}</p>`;
      }

      const statusChip = data.status === 'PENDENTE' 
        ? `<div style="background-color: #FFD700; color: black; padding: 5px; display: inline-block; border-radius: 4px; font-size: 12px; margin-top: 10px;">
             ${this.$t('components.Movimento.template.dialog.common.awaitingApproval').toString()}
           </div>`
        : data.status === 'CANCELADA'
        ? `<div style="background-color: red; color: white; padding: 5px; display: inline-block; border-radius: 4px; font-size: 12px; margin-top: 10px;">
             ${this.$t('components.Movimento.template.dialog.common.canceled').toString()}
           </div>`
        : '';

      const renderSection = (title: string, content: string) => `
        <div style="margin-top: 15px;">
          <h3 style="margin-bottom: 5px;">${title}</h3>
          ${content}
        </div>
      `;

      const renderInfoTable = (info: Array<{key: string, value: string}>) => `
        <table style="width: 100%; border-collapse: collapse;">
          ${info.map(item => `
            <tr>
              <td style="padding: 5px 0;">${item.key}</td>
              <td style="text-align: right;">${item.value}</td>
            </tr>
          `).join('')}
        </table>
      `;

      return `
        <div style="text-align: center;">
          <h2 style="margin-bottom: 10px;">${this.$t('components.Movimento.template.dialog.boletoReceipt.title').toString()}</h2>
          ${statusChip}
          
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          
          <h3>${data.BancoCedente}</h3>
          <small style="display: block; margin-top: 5px; word-break: break-all;">${data.LinhaDigitavel}</small>
          
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          
          ${renderSection(this.$t('components.Movimento.template.dialog.boletoReceipt.beneficiary').toString(), 
            renderInfoTable([
              {key: this.$t('components.Movimento.template.dialog.common.name').toString(), value: data.beneficiario.nome},
              {key: this.$t('components.Movimento.template.dialog.common.document').toString(), value: data.beneficiario.documento}
            ])
          )}
          
          ${renderSection(this.$t('components.Movimento.template.dialog.boletoReceipt.drawee').toString(), 
            renderInfoTable([
              {key: this.$t('components.Movimento.template.dialog.common.name').toString(), value: data.sacado.nome},
              {key: this.$t('components.Movimento.template.dialog.common.document').toString(), value: data.sacado.documento}
            ])
          )}
          
          ${renderSection(this.$t('components.Movimento.template.dialog.boletoReceipt.payer').toString(), 
            renderInfoTable([
              {key: this.$t('components.Movimento.template.dialog.boletoReceipt.name').toString(), value: data.pagador.nome},
              {key: this.$t('components.Movimento.template.dialog.common.document').toString(), value: data.pagador.documento}
            ])
          )}
          
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          
          <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
            <div>
              <h4>${this.$t('components.Movimento.template.dialog.boletoReceipt.dueDate').toString()}</h4>
              <p>${moment(data.DataVencimentoRegistro).locale(this.lang).format("DD/MM/yyyy")}</p>
            </div>
            ${data.status === 'PAGO' ? `
              <div>
                <h4>${this.$t('components.Movimento.template.dialog.boletoReceipt.paymentDate').toString()}</h4>
                <p>${moment(data.DataLiquidacao).locale(this.lang).format("DD/MM/yyyy")}</p>
              </div>
            ` : ''}
          </div>
          
          <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
            <div>
              <h4>${this.$t('components.Movimento.template.dialog.boletoReceipt.titleValue').toString()}</h4>
              <p>${data.ValorNominalFormatado}</p>
            </div>
            ${data.status === 'PAGO' ? `
              <div>
                <h4>${this.$t('components.Movimento.template.dialog.boletoReceipt.paidValue').toString()}</h4>
                <p>${data.ValorPagamentoAtualizadoFormatado}</p>
              </div>
            ` : ''}
          </div>
          
          ${data.status === 'PAGO' ? `
            <hr style="border: 1px solid #ccc; margin: 15px 0;" />
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
              <div>
                <h4>${this.$t('components.Movimento.template.dialog.common.transaction').toString()}</h4>
                <p>${data.Autenticacao}</p>
              </div>
              <div>
                <h4>${this.$t('components.Movimento.template.dialog.common.protocol').toString()}</h4>
                <p>${data.ProtocoloId}</p>
              </div>
            </div>
            
            <p>${this.$t('components.Movimento.template.dialog.boletoReceipt.operationDateTime').toString()}: 
               ${moment(data.DataOperacao).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")}</p>
            
            <h3>${this.$t('components.Movimento.template.dialog.boletoReceipt.validPaymentReceipt').toString()}</h3>
          ` : `
            <h3>${this.$t('components.Movimento.template.dialog.boletoReceipt.invalidPaymentReceipt').toString()}</h3>
          `}
        </div>
      `;
    },
    renderComprovanteCartao(data: ComprovanteCartao): string {
      if (!data) {
        //return '<p>Erro: Dados incompletos para gerar o comprovante</p>';
        return `<p>${this.$t('components.Movimento.template.review.incompleteDataForReceipt').toString()}</p>`;
      }

      const statusChip = data.status === 'canceled' 
        ? `<div style="background-color: red; color: white; padding: 5px; display: inline-block; border-radius: 4px; font-size: 12px; margin-top: 10px;">
             ${this.$t('components.Movimento.template.dialog.common.canceled')}
           </div>`
        : '';

      return `
        <div style="text-align: center;">
          <div style="background-color: indigo; width: 64px; height: 64px; border-radius: 50%; display: flex; justify-content: center; align-items: center; margin: 0 auto;">
            <img src="data:image/png;base64,${data.icone}" style="max-width: 36px; height: auto;" />
          </div>
          
          <h3 style="margin-top: 15px;">${data.tipo}</h3>
          ${statusChip}
          
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          
          <h4 style="margin-bottom: 10px;">
            ${moment(data.data).locale(this.lang).format("DD [de] MMMM [de] YYYY [às] HH:mm")}
          </h4>
          
          <h3>${data.estabelecimento}</h3>
          
          <h1 style="margin-top: 15px;">R$ ${data.valorFormatado}</h1>
          
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          
          <p style="margin-bottom: 5px;">${this.$t('components.Movimento.template.dialog.common.transaction')}</p>
          <p style="font-weight: bold;">${data.transacao}</p>
        </div>
      `;
    },
    renderComprovanteRecarga(data: ComprovanteRecarga): string {
      if (!data) {
        //return '<p>Erro: Dados incompletos para gerar o comprovante</p>';
        return `<p>${this.$t('components.Movimento.template.review.incompleteDataForReceipt').toString()}</p>`;
      }

      return `
        <div style="text-align: center;">
          <h2 style="margin-bottom: 10px;">${this.$t('components.Movimento.template.dialogs.rechargeReceipt.title')}</h2>
        </div>
        
        <hr style="border: 1px solid #ccc; margin: 15px 0;" />
        
        <pre style="white-space: pre-wrap; word-wrap: break-word; font-size: 12px;">${data.recarga_comprovante}</pre>
        
        <table style="width: 100%; border-collapse: collapse; margin-top: 15px;">
          <tr>
            <td style="padding: 5px 0; font-weight: bold;">${this.$t('components.Movimento.template.dialogs.common.authentication')}</td>
            <td style="text-align: right;">${data.recarga_autenticacao}</td>
          </tr>
          <tr>
            <td style="padding: 5px 0; font-weight: bold;">${this.$t('components.Movimento.template.dialogs.common.protocol')}</td>
            <td style="text-align: right;">${data.recarga_protocolo}</td>
          </tr>
        </table>
        
        <hr style="border: 1px solid #ccc; margin: 15px 0;" />
        
        <p>${data.recarga_autenticacao_api}</p>
        
        <p style="font-weight: bold; margin-top: 15px;">
          ${this.$t('components.Movimento.template.dialogs.rechargeReceipt.dateTimeLabel')}
          ${moment(data.recarga_data).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")}
        </p>
      `;
    },
    renderComprovantePix(data: any): string {
      if (!data || !data.origem || !data.destino) {
        return '<p>Erro: Dados incompletos para gerar o comprovante</p>';
      }

      return `
        <div style="text-align: center;">
          <h2 style="margin-bottom: 10px;">R$ ${data.valorFormatado}</h2>
          <p>${moment(data.dataEfetivada).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")}</p>
        </div>
        
        <hr style="border: 1px solid #ccc; margin: 15px 0;" />
        
        <h3 style="margin-top: 15px;">
          <span style="margin-right: 10px;">&#128181;</span>${this.$t('components.RelatorioPix.template.Origin')}
        </h3>
        <table style="width: 100%; border-collapse: collapse;">
          <tr>
            <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Name')}</td>
            <td style="text-align: right;">${data.origem.name || 'N/A'}</td>
          </tr>
          <tr>
            <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Document')}</td>
            <td style="text-align: right;">${data.origem.taxId || 'N/A'}</td>
          </tr>
          <tr>
            <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Institution')}</td>
            <td style="text-align: right;">${data.origem.bank || 'N/A'}</td>
          </tr>
          <tr>
            <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Agency')}</td>
            <td style="text-align: right;">${data.origem.branch || 'N/A'}</td>
          </tr>
          <tr>
            <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.AccountType')}</td>
            <td style="text-align: right;">${data.origem.accountType || 'N/A'}</td>
          </tr>
        </table>
        
        <h3 style="margin-top: 15px;">
          <span style="margin-right: 10px;">&#128182;</span>${this.$t('components.RelatorioPix.template.Destination')}
        </h3>
        <table style="width: 100%; border-collapse: collapse;">
          <tr>
            <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Name')}</td>
            <td style="text-align: right;">${data.destino.name || 'N/A'}</td>
          </tr>
          <tr>
            <td style="padding: 5px 0;">${this.$t('components.RelatorioPix.template.Document')}</td>
            <td style="text-align: right;">${data.destino.taxId || 'N/A'}</td>
          </tr>
        </table>
        
        <div style="text-align: center; margin-top: 20px;">
          <h3>${this.$t('components.RelatorioPix.template.Authentication')}</h3>
          <p>${data.endToEndId || 'N/A'}</p>
        </div>
      `;
    },
    renderComprovanteTransferencia(data: ComprovanteTransferencia): string {
      if (!data) {
        return '<p>Erro: Dados incompletos para gerar o comprovante</p>';
      }

      const renderInfoTable = (info: Array<{key: string, value: string}>) => `
        <table style="width: 100%; border-collapse: collapse;">
          ${info.map(item => `
            <tr>
              <td style="padding: 5px 0;">${item.key}</td>
              <td style="text-align: right;">${item.value}</td>
            </tr>
          `).join('')}
        </table>
      `;

      const origemInfo = [
        {key: this.$t('components.Movimento.template.dialog.common.name').toString(), value: data.origem.nome},
        {key: this.$t('components.Movimento.template.dialog.common.document').toString(), value: data.origem.documento}
      ];

      const destinoInfo = [
        {key: this.$t('components.Movimento.template.dialog.common.name').toString(), value: data.destino.nome},
        {key: this.$t('components.Movimento.template.dialog.common.document').toString(), value: data.destino.documento}
      ];

      return `
        <div style="text-align: center;">
          <h3 class="fw-bolder mt-5 mb-3">${this.$t('components.Movimento.template.dialog.transferReceipt.transferReceipt')}</h3>
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
          <h2 class="mt-3 mb-3">R$ ${data.valorFormatado}</h2>
          <hr style="border: 1px solid #ccc; margin: 15px 0;" />
        </div>

        <h3 class="mt-3">${this.$t('components.Movimento.template.dialog.common.origin')}</h3>
        ${renderInfoTable(origemInfo)}

        <h3 class="mt-5">${this.$t('components.Movimento.template.dialog.transferReceipt.destination')}</h3>
        ${renderInfoTable(destinoInfo)}

        <hr style="border: 1px solid #ccc; margin: 15px 0;" />

        <div style="margin-top: 15px;">
          <p>${this.$t('components.Movimento.template.dialog.transferReceipt.date').toString()}:
             ${moment(data.data).locale(this.lang).format("DD/MM/yyyy HH:mm:ss")}</p>
          <p>${this.$t('components.Movimento.template.dialog.transferReceipt.identification').toString()}: ${data.identificacao}</p>
          <p>${this.$t('components.Movimento.template.dialog.common.transaction').toString()}: <strong>${data.transacao}</strong></p>
        </div>
      `;
    },

    openDialog(dialogName: string) {
      switch (dialogName) {
        case 'comprovanteTransferenciaDialog':
          this.dialogComprovanteTransferencia = true;
          break;
        case 'comprovanteSaqueDialog':
          this.dialogComprovanteSaque = true;
          break;
        case 'comprovanteBoletoDialog':
          this.dialogComprovanteBoleto = true;
          break;
        case 'comprovanteCartaoDialog':
          this.dialogComprovanteCartao = true;
          break;
        case 'comprovanteRecargaDialog':
          this.dialogComprovanteRecarga = true;
          break;
        case 'comprovantePixRecebidoDialog':
          this.dialogComprovantePixRecebido = true;
          break;
        case 'comprovantePixEnviadoDialog':
          this.dialogComprovantePixEnviado = true;
          break;
        default:
          //console.error(`Diálogo desconhecido: ${dialogName}`);
      }
    },
    Comprovante(origem: number, id: string) {
      
      Overlay.show();
      let pathUri = "";
      if (origem == 6 || origem == 5) {
        pathUri = "comprovantes/transferencias/" + id;
      } else if (origem == 10) {
        pathUri = "comprovantes/saques/" + id;
      } else if (origem == 21) {
        pathUri = "comprovantes/boleto/" + id;
      } else if (origem == 25) {
        pathUri = "comprovantes/cartao/" + id;
      } else if (origem == 18) {
        pathUri = "comprovantes/recargas/" + id;
      } else if (origem == 35 || origem == 34) {
        pathUri = "comprovantes/pix/receive/" + id;
      } else if (origem == 36) { //PIX ENVIADO
        pathUri = "comprovantes/pix/sent/" + id;
        
      } else {
        Snackbar.show("Tipo de comprovante não reconhecido", "error");
        Overlay.hide();
        return;
      }

      Api.get("/v2/public/" + pathUri)
        .then((response) => {
          

          if (origem == 6 || origem == 5) {
            this.dadosComprovanteTransferencia = response.data.body;
            if (this.dadosComprovanteTransferencia) {
              this.dialogComprovanteTransferencia = true;
              
            }
          } else if (origem == 10) {
            this.dadosComprovanteSaque = response.data.body;
            if (this.dadosComprovanteSaque) {
              this.dialogComprovanteSaque = true;
              
            }
          } else if (origem == 21) {
            this.dadosComprovanteBoleto = response.data.body;
            if (this.dadosComprovanteBoleto) {
              this.dialogComprovanteBoleto = true;
              
            }
          } else if (origem == 25) {
            this.dadosComprovanteCartao = response.data.body;
            if (this.dadosComprovanteCartao) {
              this.dialogComprovanteCartao = true;
              
            }
          } else if (origem == 18) {
            this.dadosComprovanteRecarga = response.data.body;
            if (this.dadosComprovanteRecarga) {
              this.dialogComprovanteRecarga = true;
              
            }
          } else if (origem == 36) {
            this.dadosComprovantePixEnviado = response.data.body;
            
            if (!this.dadosComprovantePixEnviado?.destino?.accountType) {
              console.warn('Tipo de conta não encontrado nos dados');
            }
            if (this.dadosComprovantePixEnviado) {
              this.dialogComprovantePixEnviado = true;
              
            }
          } else if (origem == 35 || origem == 34) {
            this.dadosComprovantePixRecebido = response.data.body;
            if (this.dadosComprovantePixRecebido) {
              this.dialogComprovantePixRecebido = true;
              
            }
          }
        })
        .catch((error: any) => {
         
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          Snackbar.show(data.body.error, "error");
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    next(page: number) {
      this.page = page;
      this.loadDatas();
    },
    reloadFilters() {
      this.btnReloadLoading = true;
      this.startPeriod = this.defaultStartPeriod;
      this.endPeriod = this.defaultEndPeriod;
      this.doDelayedSearch();
    },
    doDelayedSearch() {
      this.dialogFiltrosMobile = false;
      this.page = 1;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        //this is your existing function
        this.loadDatas();
      }, 500);
    },
    formataValor(data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },
    loadDatas() {
      const intervalo = Math.round(
        (new Date(this.endPeriod).getTime() -
          new Date(this.startPeriod).getTime()) /
          (1000 * 3600 * 24)
      );

      if (intervalo > 31) {
        Snackbar.show(
          this.$t('components.Movimento.template.alerts.intervalTooLong').toString(),
          "error"
        );
        return false;
      }
      this.errors = [];
      this.hasLoadedData = true;
      Overlay.show();

      this.loadingDesserts = true;
      Api.get("/v2/colaboradores/movimento", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          entryType: this.selectedEntryTipoMovimentos.id,
          filterType: this.selectedEntryFiltrosMovimentos.id,
          page: this.page,
          export: this.toExport,
        },
      })
        .then((response) => {
          const data = response.data.body.efetivadas.rows;
          // this gives an object with dates as keys
          const groups = data.reduce((groups: any, rows: any) => {
            const date = rows.data.split(" ")[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(rows);
            return groups;
          }, {});

          // Edit: to add it in the array format instead
          const groupArrays = Object.keys(groups).map((date) => {
            return {
              date,
              rows: groups[date],
            };
          });

          this.desserts = groupArrays;
          //console.log(this.desserts);
          this.filtrosMobile =
            moment(this.startDate).locale(this.lang).format("DD/MM/yyyy") +
            "-" +
            moment(this.endDate).locale(this.lang).format("DD/MM/yyyy");
          this.totalDesserts = response.data.body.efetivadas.count.__total;
          this.pages = response.data.body.efetivadas.count.pages;
          this.totalEntradas = response.data.body.efetivadas.entradas;
          this.totalSaidas = response.data.body.efetivadas.saidas;
          //this.saldoCliente = response.data.body.saldo.saldoFormatado;
          this.somaDaPagina = response.data.body.efetivadas.somaPaginaFormatado;
          this.somaTotal = response.data.body.efetivadas.somaTotalFormatado;
          // this.panel = {...Array(this.totalDesserts).keys()].map((k, i) => i}
          if (this.totalDesserts == 0) {
            this.btnDownloadDisabled = true;
          } else {
            this.btnDownloadDisabled = false;
          }
          this.panel = [
            ...Array(response.data.body.efetivadas.count.__this).keys(),
          ].map((k, i) => i);
          Overlay.hide();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingDesserts = false;
          this.btnReloadLoading = false;
        });
    },
    downloadReport() {
      this.btnDownloadLoading = true;
      Overlay.show();

      Api.get("/v2/colaboradores/financeiro/export", {
        params: {
          startDate: this.startPeriod,
          endDate: this.endPeriod,
          entryType: this.selectedEntryTipoMovimentos.id,
          filterType: this.selectedEntryFiltrosMovimentos.id,
          page: this.page,
          export: this.toExport,
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "extrato_" + this.startPeriod + "-" + this.endPeriod + ".xlsx";
          link.click();
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnDownloadLoading = false;
        });
    },
    getBalance() {
      this.loadingBalance = true;
      Api.get("/v2/colaboradores/financeiro/saldo")
        .then((response) => {
          this.saldoCliente = response.data.body.saldoFormatado;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status === 422) {
            this.errors = data.body.errors;
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.loadingBalance = false;
        });
    },

   
  },
  computed: {
    localizedTipoMovimentos() {
      return [
        { id: "T", descricao: this.$t('components.Movimento.template.accountMovement.movementTypes.all') },
        { id: "C", descricao: this.$t('components.Movimento.template.accountMovement.movementTypes.inputs') },
        { id: "D", descricao: this.$t('components.Movimento.template.accountMovement.movementTypes.outputs') },
      ];
    },
    filtrosMovimentos() {
      return [
        { id: "T", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.All') },
        { id: "35", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.PixReceived') },
        { id: "36", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.PixSent') },
        { id: "37", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.PixReversed') },
        { id: "38", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.PixSentFee') },
        { id: "39", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.PixReceivedFee') },
        { id: "16", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.MovementFee') },
        { id: "15", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.BankWithdrawalFee') },
        { id: "6", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.TransferSent') },
        { id: "5", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.TransferReceived') },
        { id: "41", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.PixRefund') },
        { id: "10", descricao: this.$t('components.Movimento.template.accountMovement.MovementFilters.WithdrawalToBankAccount') },
      ];
    }
  }
});
