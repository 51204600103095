
import Vue from "vue";

import moment from "moment";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import Auth from "@/lib/Auth";
export default Vue.extend({
  name: "homeMenuPix",

  data() {
    return {
      ModuloInfo: [],
      emv: "",
      mfaEnabled: true,
      alert: {} as Alert,
      loadingData: true,
      btnloading: false,
      btnDisabled: true,
      dialogPixCopyPaste: false,
      isMobile: false,
      lang: navigator.language,
      errors: [],
      moment: moment,
      breadcrumbs: [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea"),
          value: "pix",
          disabled: false,
          href: "/pix",
        },
        {
          text: this.$t("components.preCadastro.template.form.PayPix"),
          disabled: true,
          href: "/pix/menuPix",
        },
      ],

      hasInfoSituacaoConta: false,
      situacaoConta: {},
      loadedData: false,
      permissoes: {
        perm_pix_transfer: false,
      },
    };
  },
  created() {
    this.getSituacaoConta();
    this.updateBreadcrumbs();
    this.permissoes = Auth.getUserPermissoes();
    if (!this.permissoes.perm_pix_transfer) {
      this.$router.go(-1);
    }
    sessionStorage.removeItem("emvData");
    this.isMobile = this.detectMobile();
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.updateBreadcrumbs();
      },
      immediate: true,
    },
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    async getSituacaoConta() {
      Overlay.show();
      await Api.get("/v2/colaboradores/dashboard/statusConta")
        .then((response) => {
          this.situacaoConta = response.data.body;
          this.hasInfoSituacaoConta = true;
          this.loadedData = true;
          this.mfaEnabled = response.data.body.mfaEnabled;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
        })
        .finally(() => {
          Overlay.hide();
        });
    },
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.PixArea"),
          value: "pix",
          disabled: false,
          href: "/pix",
        },
        {
          text: this.$t("components.preCadastro.template.form.PayPix"),
          disabled: true,
          href: "/pix/menuPix",
        },
      ];
    },
  },
});
