
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { Login } from "@/models/Login";
import Axios, { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import { Overlay } from "@/lib/Overlay";
import router from "@/router";
import VueRecaptcha from "vue-recaptcha";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";

export default Vue.extend({
  name: "Login",
  data: () => ({
    rand: 1,
    isMobile: false,
    windowOverlay: false,
    dialog2FALogin: false,
    uriLogin: "/v2/colaboradores/auth",
    showpass: false,
    valid: true,
    form: {} as Login,
    errors: [],
    btnLoading: false,
    lembraLogin: false,
    hasRegisteredDevice: false,
    clientIp: "0.0.0.0",
    deviceInfo: {},
    sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  }),
  components: {
    "vue-recaptcha": VueRecaptcha,
    LocaleSwitcher,
  },
  created() {
    this.form.code = "";
    this.isMobile = this.detectMobile();
    if (
      sessionStorage.deviceRegistered == undefined ||
      sessionStorage.deviceRegistered == null
    ) {
      this.hasRegisteredDevice = false;
    } else {
      this.hasRegisteredDevice = true;
    }

    this.rand = new Date().getTime();
    if (localStorage.getItem("lembrarDadosAcesso")) {
      const dadosAcesso = JSON.parse(
        String(localStorage.getItem("lembrarDadosAcesso"))
      );
      this.form.login = dadosAcesso.login;
      this.form.lembrar = true;
      this.lembraLogin = true;
      this.form.dadosLembrar = JSON.parse(
        String(localStorage.getItem("lembrarDadosAcesso"))
      );
      // this.form.login= this.dadosLoginLembrar.login;
      // console.log(this.form.dadosLembrar.avatar);
    }

    // this.getUserIP();
  },

  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    randomNumber: function () {
      return Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    },
    removeLembrar() {
      localStorage.removeItem("lembrarDadosAcesso");
      (this.form.login = ""), (this.lembraLogin = false);
    },
    doLogin(token: string): void {
      this.errors = [];
      this.btnLoading = true;
      Overlay.show();
      Api.post(this.uriLogin, this.form, {
        headers: {
          recaptcha: token,
        },
      })
        .then(
          (response) => {
            Overlay.show();

            // console.log("deu certo");
            if (this.form.lembrar) {
              localStorage.setItem(
                "lembrarDadosAcesso",
                JSON.stringify({
                  login: this.form.login,
                })
              );
            } else {
              localStorage.removeItem("lembrarDadosAcesso");
            }
            // console.log(response.data.body.token);

            Auth.login(response.data.body.token);
          }
          //  Auth.login(response.data.token);
        )
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.form.code = "";
          if (status === 422) {
            this.errors = data.body.errors;
          } else if (status === 302) {
            this.$nextTick(() => {
              const wrapper = this.$refs.code as any;

              window.setTimeout(() => wrapper?.focus(), 0);
            });
            this.uriLogin = "/v2/colaboradores/auth/2fa";
            this.dialog2FALogin = true;
          } else {
            Swal.fire({
              text: data.body.error,
              icon: "error",
              confirmButtonText: "Ok",
              showConfirmButton: true,
              customClass: {
                confirmButton: "d-block",
              },
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          this.btnLoading = false;
          //  console.log("finalizou");

          Overlay.hide();
          this.resetRecaptcha();
        });
    },
    onSubmit: function () {
      //this.windowOverlay = true;

      (this.$refs.invisibleRecaptcha as HTMLFormElement).execute();
    },
    onVerify: function (response: string) {
      this.doLogin(response);
    },
    onExpired: function () {
      this.resetRecaptcha();
    },
    resetRecaptcha() {
      (this.$refs.invisibleRecaptcha as HTMLFormElement).reset(); // Direct call reset method
    },
    cancela2FA() {
      this.uriLogin = "/v2/client/auth";
      this.form.code = "";
      this.dialog2FALogin = false;
      this.form.password = "";
    },
    getUserIP: function () {
      /*
      Overlay.show();
      fetch("https://api.ipify.org?format=json")
        .then((response) => response.json())
        .then((response) => {
          this.form.deviceInfo = {
            useragent: navigator.userAgent,
            ip: response.ip,
            lat_long: "",
            imei: "",
            mac: "",
          };
        })
        .finally(() => {
          Overlay.hide();
        });
        */
    },
  },
});
