
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import { Alert } from "@/lib/Alert";
import moment from "moment";
import { PagamentoPix } from "@/models/PagamentoPix";
import { VMoney } from "v-money";
import Swal from "sweetalert2";
import { Snackbar } from "@/lib/Snackbar";
import jwtDecode from "jwt-decode";
import router from "@/router";
import Auth from "@/lib/Auth";



interface ComponentData {
  isMobile: boolean;
  mfaEnabled: boolean;
  dialogPixEmProcessamento: boolean;
  dialogRevisaoTransferPix: boolean;
  formPagtoPix: PagamentoPix;
  ModuloInfo: {
    moduloAtivo: boolean;
    moduloBloqueioMotivo: number;
    valorMinFormatado: string;
    
    [key: string]: any; 
  };
  emv: string;
  alert: Alert;
  loadingData: boolean;
  btnloading: boolean;
  btnDisabled: boolean;
  dialogPixCopyPaste: boolean;
  valorPagamento: number;
  curStep: number;
  lang: string;
  errors: { [key: string]: string };
  moment: typeof moment;
  money: {
    decimal: string;
    thousands: string;
    prefix: string;
    suffix: string;
    precision: number;
    masked: boolean;
  };
  emvData: any;
  ckStatusPagto: boolean;
  progressPixCkinterval: number;
  progressTempoCkPix: number;
  idPixChecking: string;
  qtdCheckStatus: number;
  valid: boolean;
  dialogDefineValorPagto: boolean;
  loadingDataEMV: boolean;
  transactionPin: string;
  permissoes: {
    perm_pix_transfer: boolean;
  };
}

interface Component extends Vue, ComponentData {
  $data: ComponentData;
  detectMobile: () => boolean;
  readEMVJWT: () => void;
  moduloInfo: () => void;
  cancelaPagamento: () => void;
  editaValorPagamento: () => void;
  showRevisao: () => void;
  ckLenghtEMV: () => void;
  formataValor: (data: number) => string;
  defineValorPix: () => void;
  transferePix: () => void;
  resetModulo: () => void;
  showAlert: (show?: boolean, message?: string, type?: string) => void;
  $t: (key: string) => string;  
  $refs: any;
  errorMessages: { transactionPin: string };
  $nextTick: {
    (): Promise<void>;
    <T>(callback: (this: T) => void): void;
    <T>(callback: (this: T) => void, context: T): void;
  };
}

export default Vue.extend<any, any, any, ComponentData>({
  name: "TransferirPixEMV",

  data() {
    return {
      mfaEnabled: true,
      dialogPixEmProcessamento: false,
      dialogRevisaoTransferPix: false,
      formPagtoPix: {} as PagamentoPix,
      ModuloInfo: [],
      emv: "",
      alert: {} as Alert,
      loadingData: true,
      btnloading: false,
      btnDisabled: true,
      dialogPixCopyPaste: false,
      isMobile: false as boolean,
      valorPagamento: 0,
      curStep: 2,
      lang: navigator.language,
      errors: {} as { [key: string]: string },
      moment: moment,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      emvData: {},
      ckStatusPagto: false,
      progressPixCkinterval: 0,
      progressTempoCkPix: 0,
      idPixChecking: "",
      qtdCheckStatus: 0,
      valid: true,
      dialogDefineValorPagto: false,
      loadingDataEMV: true,
      transactionPin: "",
      permissoes: {
        perm_pix_transfer: false,
      },
    };
  },

  created(this: Component): void {
    this.isMobile = this.detectMobile();

    if (sessionStorage.emvData == undefined || sessionStorage.emvData == null) {
      Snackbar.show(this.$t('components.TransferenciasPixEMV.template.invalid_qrcode').toString(), "error");
      router.push("/pix/transferir/emv");
    }
    this.readEMVJWT();
    this.moduloInfo();
  },

  methods: {
    readEMVJWT(this: Component) {
      const emv = jwtDecode(sessionStorage.getItem("emvData")) as any;
      console.log(emv.data);
      this.emvData = emv.data;
      this.valorPagamento = emv.data.payment.amount;
      this.loadingDataEMV = false; 

    },
    detectMobile(this: Component) {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    cancelaPagamento(this: Component) {
      this.transactionPin = "";

      this.dialogRevisaoTransferPix = false;
    },
    editaValorPagamento(this: Component) {
      this.dialogDefineValorPagto = true;
      this.$nextTick(() => {
        const wrapper = this.$refs.valorTransferencia as any;
        window.setTimeout(() => wrapper?.focus(), 0);
      });
    },
    showRevisao(this: Component) {
     
      if (
        this.valorPagamento == null ||
        this.valorPagamento == 0 ||
        this.valorPagamento.toString() === "" ||
        this.valorPagamento.toString() === "R$ 0,00" ||
        this.valorPagamento.toString() === "0,00" ||
        this.valorPagamento.toString() === "0.00"
      ) {
        Snackbar.show(this.$t('components.TransferenciasPixEMV.template.enter_value').toString(), "error");
        this.editaValorPagamento();
        this.$nextTick(() => {
          const wrapper = this.$refs.valorPagamento as any;

          window.setTimeout(() => wrapper?.focus(), 0);
        });
      } else {
        this.dialogRevisaoTransferPix = true;
        this.$nextTick(() => {
          const wrapper = this.$refs.pincode as any;

          window.setTimeout(() => wrapper?.focus(), 0);
        });
      }
      
    },
    moduloInfo(this: Component) {

      
      
      
      Overlay.show();

      this.loadingData = true;
      Api.get("/v2/colaboradores/financeiro/pix/info")
        .then((response) => {
          this.ModuloInfo = response.data.body.moduloInfo;
          this.loadingData = false;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;

          if (status != 200) {
            //exe
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
        });
        
    },
    ckLenghtEMV(this: Component) {
      this.showAlert();
      if (this.emv.length > 50) {
        this.btnDisabled = false;
      }
    },
    formataValor(this: Component, data: number) {
      const valor = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(data);

      return valor.toString().replace("R$", "").trim();
    },

    defineValorPix(this: Component) {
      if (
        this.valorPagamento == null ||
        this.valorPagamento.toString() === "" ||
        this.valorPagamento.toString() === "R$ 0,00" ||
        this.valorPagamento.toString() === "0,00" ||
        this.valorPagamento.toString() === "0.00"
      ) {
        Snackbar.show(this.$t('components.TransferenciasPixEMV.template.invalid_value').toString(), "error");
      } else {
        this.dialogDefineValorPagto = false;
      }
    },
    transferePix(this: Component) {
      
      this.btnloading = true;
      this.errors = {};
      if (
        this.transactionPin === "" ||
        this.transactionPin.toString().length < 6
      ) {
        this.errors["transactionPin"] = this.errorMessages.transactionPin;
        Snackbar.show(this.$t('components.TransferenciasPixEMV.template.enter_pin').toString(), "error");
        this.btnloading = false;
        return false;
      }
      if (
        this.valorPagamento.toString() === "" ||
        this.valorPagamento.toString() === "0.00" ||
        this.valorPagamento.toString() === "0,00"
      ) {
        Snackbar.show(this.$t('components.TransferenciasPixEMV.template.enter_value').toString(), "error");
        this.btnloading = false;
        return false;
      }
      Overlay.show();
      this.btnloading = true;
      this.dialogRevisaoTransferPix = false;
      //this.dialogProcessandoPix = true;
      Api.post("/v2/colaboradores/financeiro/pix/qrcode/process", {
        emv: sessionStorage.getItem("emvData"),
        amount: this.valorPagamento,
        transactionPin: this.transactionPin,
      })
        .then((response) => {
          this.dialogRevisaoTransferPix = false;
          this.dialogPixEmProcessamento = true;
          sessionStorage.removeItem("emvData");
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status != 200) {
            this.dialogRevisaoTransferPix = true;
            //this.dialogProcessandoPix = false;
            Swal.fire({
              title: "Opps",
              text: data.body.error,
              icon: "error",
            });
          }
          // this.resetRecaptcha();
        })
        .finally(() => {
          Overlay.hide();
          this.btnloading = false;
        });
        
    },
    resetModulo(this: Component) {
      this.emv = "";
      sessionStorage.removeItem("emvData");
    },

    showAlert(this: Component, show = false, message = "", type = "error"): void {
      this.alert = {
        show,
        type,
        message,
      };
    },
  },
  computed: {
    localizedBreadcrumbs(this: Component) {
      return [
        {
          text: this.$t('components.TransferenciasPixEMV.template.breadcrumbs.home'),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t('components.TransferenciasPixEMV.template.breadcrumbs.pixArea'),
          disabled: false,
          href: "/pix",
        },
        {
          text: this.$t('components.TransferenciasPixEMV.template.breadcrumbs.payWithPix'),
          disabled: true,
          href: "/pix/menuPix",
        },
      ];
    },
    errorMessages(this: Component) {
      return {
        transactionPin: this.$t('components.TransferenciasPixEMV.template.enter_transaction_pin')
      };
    }
  },
  directives: { money: VMoney },
});
