import { render, staticRenderFns } from "./TransferenciasPixChave.vue?vue&type=template&id=511ca32c"
import script from "./TransferenciasPixChave.vue?vue&type=script&lang=ts"
export * from "./TransferenciasPixChave.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports