
import Vue from "vue";
import Auth from "@/lib/Auth";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

export default Vue.extend({
  name: "meuQRCode",

  data() {
    return {
      sheet: false,
      PixData: [],
      loadingData: true,
      moduloAtivo: {
        ativo: true,
        mensagem: "",
      },
      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
      // Ensure that breadcrumbs is declared in data
      breadcrumbs: [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.QRCode"),
          disabled: true,
          href: "/pix/meu-qr-code",
        },
      ],
      permissoes: {
        perm_pix_recebidos: false,
        perm_pix_enviados: false,
        perm_qrcode_static: false,
      },
    };
  },

  created() {
    this.permissoes = Auth.getUserPermissoes();
    if (!this.permissoes.perm_qrcode_static) {
      this.$router.go(-1);
    }
    this.isMobile = this.detectMobile();
    this.moduloInfo();
    this.updateBreadcrumbs();
  },

  watch: {
    '$i18n.locale': {
      handler() {
        this.updateBreadcrumbs();
      },
      immediate: true,
    },
  },

  methods: {
    detectMobile() {
      return screen.width <= 760;
    },
    moduloInfo() {
      const id = this.$route.params.id;
      Overlay.show();
      this.loadingData = true;

      Api.get("/v2/colaboradores/financeiro/adicionar/pix/meu-qr-code")
        .then((response) => {
          this.PixData = response.data.body;
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          if (status !== 200) {
            if (status === 406) {
              this.moduloAtivo.ativo = false;
              this.moduloAtivo.mensagem = data.body.error;
            } else {
              window.location.replace("/");
            }
          }
        })
        .finally(() => {
          Overlay.hide();
          this.loadingData = false;
        });
    },
    doCopy(str: string) {
      this.$copyText(str).then(function () {
        Snackbar.show("Copiado com sucesso!");
      });
    },
    onCopy() {
      Snackbar.show("Copiado com sucesso!");
    },
    onCopyError() {
      Snackbar.show("Erro ao copiar para área de transferência", "error");
    },
    // Move updateBreadcrumbs into methods
    updateBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: this.$t("components.preCadastro.template.form.Home"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("components.preCadastro.template.form.QRCode"),
          disabled: true,
          href: "/pix/meu-qr-code",
        },
      ];
    },
  },
});
