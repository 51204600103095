
import Vue from "vue";
import Api from "@/services/Api";
import { AxiosResponse } from "axios";
import { Overlay } from "@/lib/Overlay";
import moment from "moment";
import { Snackbar } from "@/lib/Snackbar";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "TwoAuthenticator",

  data() {
    return {
      code: "",
      pinCode: "",
      isMobile: false,
      dialogSetup2FA: false,
      dialogRemove2FA: false,
      lang: navigator.language,
      twoFactorStatus: false,
      twoFactorSecret: "",
      twoFactorQR: "",
      errors: [],
      moment: moment,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    this.get2FAStatus();
  },
  methods: {
    testSwal() {
      Snackbar.show(
        this.$t('components.TwoAuthenticator.template.validation.enterAuthenticatorCode').toString(),
        'error'
      );
    },

    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    get2FAStatus() {
      Overlay.show();

      Api.get("/v2/colaboradores/security/2fa/status")
        .then((response) => {
          this.twoFactorStatus = response.data.body.totp;
        })

        .finally(() => {
          Overlay.hide();
        });
    },

    getQRCode2FA() {
      Overlay.show();

      Api.get("/v2/colaboradores/security/2fa/getQRCode")
        .then((response) => {
          this.twoFactorQR = response.data.body.qrCode;
          this.twoFactorSecret = response.data.body.secret;

          this.dialogSetup2FA = true;
        })

        .finally(() => {
          Overlay.hide();
        });
    },

    ativar2FA() {
      if (this.code.length != 6) {
        Snackbar.show(
          this.$t('components.TwoAuthenticator.template.validation.enterAuthenticatorCode').toString(),
          "error"
        );
        return false;
      }
      if (this.pinCode.length != 6) {
        Snackbar.show(this.$t('components.TwoAuthenticator.template.validation.enterTransactionPin').toString(), "error");
        return false;
      }
      this.dialogSetup2FA = false;
      Overlay.show();

      Api.post("/v2/colaboradores/security/2fa/register", {
        transactionPin: this.pinCode,
        code: this.code,
        secret: this.twoFactorSecret,
      })
        .then((response) => {
          this.code = "";
          this.pinCode = "";
          Swal.fire({
            title: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.get2FAStatus();
          this.pinCode = "";
          this.code = "";
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;
          this.dialogSetup2FA = true;
          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.pinCode = "";
        })
        .finally(() => {
          Overlay.hide();
        });
    },

    remove2FA() {
      this.dialogRemove2FA = false;
      Overlay.show();

      Api.post("/v2/colaboradores/security/2fa/unregister", {
        transactionPin: this.pinCode,
      })
        .then((response) => {
          this.code = "";
          this.pinCode = "";
          Swal.fire({
            title: response.data.body.message,
            icon: "success",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.get2FAStatus();
          this.pinCode = "";
          this.code = "";
        })
        .catch((error: any) => {
          const response = error.response as AxiosResponse;
          const status = response.status;
          const data = response.data;

          Swal.fire({
            text: data.body.error,
            icon: "error",
            confirmButtonText: "Ok",
            showConfirmButton: true,
            customClass: {
              confirmButton: "d-block",
            },
          });
          this.pinCode = "";
        })
        .finally(() => {
          Overlay.hide();
        });
    },
  },
});
