
import Vue from "vue";

import moment from "moment";

import TwoAuthenticator from "@/views/seguranca/TwoAuthenticator.vue";
import Swal from "sweetalert2";
export default Vue.extend({
  name: "SegurancaHome",

  data() {
    return {
      loadingData: true,

      lang: navigator.language,
      errors: [],
      moment: moment,
      isMobile: false,
    };
  },
  created() {
    this.isMobile = this.detectMobile();
    //this.moduloInfo();
  },
  components: {
    TwoAuthenticator,
  },
  methods: {
    detectMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
});
